import React from "react"
import PropTypes from "prop-types"
import { Container, Jumbotron, Image } from "react-bootstrap"
import { Link } from "gatsby"

const ContactSection = ({
  heading,
  text,
  textLink,
  buttonText,
  image,
  styleClass,
  setModalShow,
}) => (
  <Jumbotron className={`contact__jumbo ${styleClass}`}>
    {image && (
      <Image className="contact__jumbo--image" src={image} fluid></Image>
    )}
    <Container>
      <h1>{heading}</h1>
      <p>
        {text}{" "}
        <Link to="/jobs" className="contact__jobs-link">
          {textLink}
        </Link>
      </p>
      <button className="contact__button" onClick={() => setModalShow(true)}>
        {buttonText}
      </button>
    </Container>
  </Jumbotron>
)

ContactSection.propTypes = {
  image: PropTypes.string,
  heading: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  textLink: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  styleClass: PropTypes.string,
  setModalShow: PropTypes.func.isRequired,
}

export default ContactSection
