import React from "react"
import Text from "../text.json"
import PropTypes from "prop-types"

const AlertBar = ({ french }) => {
  return (
    <div className="homepage__call">
      {french ? Text.fr.alertBar__text : Text.en.alertBar__text}{" "}
      <a href="tel:4382299397">(438) 229-9397</a>
    </div>
  )
}

AlertBar.propTypes = {
  french: PropTypes.bool.isRequired,
}

export default AlertBar
