import React from "react"
import ContactForm from "./shared/contactForm"
import { Modal } from "react-bootstrap"

const ModalFormContact = ({ french, formTitle, show, onHide }) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {formTitle}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ContactForm french={french} formTitle={formTitle} />
      </Modal.Body>
    </Modal>
  )
}

export default ModalFormContact
