import React, { useState } from "react"
import FormSuccess from "./formSuccess"
import Text from "../../text.json"
import { Button, Form } from "react-bootstrap"
import { sendContactForm } from "../../utils/formSubmission"

const ContactForm = ({ french, formTitle }) => {
  const [email, setEmail] = useState(null)
  const [name, setName] = useState(null)
  const [textArea, setTextArea] = useState(null)
  const [formSubmitted, setFormSubmitted] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    let newFormData = new FormData()
    newFormData.set("email", email)
    newFormData.set("name", name)
    newFormData.set("message", textArea)
    sendContactForm(newFormData)
    setFormSubmitted(true)
  }

  return (
    <Form onSubmit={handleSubmit}>
      {formSubmitted ? (
        <FormSuccess
        isJobForm={false}
          message="Contact Form Submitted"
          subMessage="Our team will get in touch shortly."
        />
      ) : (
        <>
          <Form.Group controlId="formName">
            <Form.Label>
              {Text[french ? "fr" : "en"].forms["name__label"]}
            </Form.Label>
            <Form.Control
              type="text"
              placeholder={
                Text[french ? "fr" : "en"].forms["name__placeholder"]
              }
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Form.Group>
          <Form.Group controlId="formEmail">
            <Form.Label>
              {Text[french ? "fr" : "en"].forms["email__label"]}
            </Form.Label>
            <Form.Control
              type="email"
              placeholder={
                Text[french ? "fr" : "en"].forms["email__placeholder"]
              }
              value={email || ""}
              onChange={e => setEmail(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group controlId="formTextArea">
            <Form.Label>
              {Text[french ? "fr" : "en"].forms["message__label"]}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows="3"
              value={textArea}
              onChange={e => setTextArea(e.target.value)}
              required
            />
          </Form.Group>
          <Button variant="primary" type="submit">
            {Text[french ? "fr" : "en"].forms["button__submit"]}
          </Button>
        </>
      )}
    </Form>
  )
}

export default ContactForm
