import React from "react"
import AlertBar from "../components/alertBar"
import Layout from "../components/shared/layout"
import SEO from "../components/seo"
import ModalFormContact from "../components/modal-form-contact"
import ContactSection from "../components/shared/contact-section"
import HandShake from "../images/handshake.jpg"
import Text from "../text.json"
import "../components/styles/contact.scss"

const ContactEmployer = () => {
  const [modalShow, setModalShow] = React.useState(false)
  let pageDescription = "Atteignez plus de 2 millions de candidats qualifiés tout en ayant l’esprit tranquille face à la gestion de vos ressources humaines."
  return (
    <>
      <AlertBar french={true} />
      <Layout french={true}>
        <SEO title="Service aux entreprises" description={pageDescription} lang="fr" />
        <section className="contact__section">
          <ContactSection
            heading={Text.fr.employer["section-1__heading"]}
            text={Text.fr.employer["section-1__text"]}
            buttonText={Text.fr.employer["section-1__button"]}
            setModalShow={setModalShow}
          />
          <ContactSection
            heading={Text.fr.employer["section-2__heading"]}
            text={Text.fr.employer["section-2__text"]}
            buttonText={Text.fr.employer["section-2__button"]}
            image={HandShake}
            styleClass={"contact__jumbo--white"}
            setModalShow={setModalShow}
          />
          <ContactSection
            heading={Text.fr.employer["section-3__heading"]}
            text={Text.fr.employer["section-3__text"]}
            buttonText={Text.fr.employer["section-3__button"]}
            styleClass={"contact__jumbo--orange"}
            setModalShow={setModalShow}
          />
          <ContactSection
            heading={Text.fr.employer["section-4__heading"]}
            text={Text.fr.employer["section-4__text"]}
            buttonText={Text.fr.employer["section-4__button"]}
            styleClass={"contact__jumbo--white"}
            setModalShow={setModalShow}
          />
          <ModalFormContact
            french={true}
            formTitle={"Contact Form"}
            show={modalShow}
            onHide={() => setModalShow(false)}
          />
        </section>
      </Layout>
    </>
  )
}

export default ContactEmployer
